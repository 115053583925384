





























html {
  background-color: white;
}

.q-page {
  background-image: url('~assets/gohi-login-bg.jpg');
  background-size: cover;
  background-position: top;
}

.login-layout {
  min-width: 425px;

  >>> .q-btn.text-white {
    color: #fff !important;
  }
}

.logo {
  width: 45%;
  max-width: 300px;
}
