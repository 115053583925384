html {
  background-color: #fff;
}
.q-page {
  background-image: url("~assets/gohi-login-bg.jpg");
  background-size: cover;
  background-position: top;
}
.login-layout {
  min-width: 425px;
}
.login-layout >>> .q-btn.text-white {
  color: #fff;
}
.logo {
  width: 45%;
  max-width: 300px;
}
/*# sourceMappingURL=src/layouts/login/gohi/index.css.map */